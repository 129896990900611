*{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-transform: capitalize;
    transition: all .2s ease-out;
    text-decoration: none;
}

section{
    padding: 7rem 0;
}

a { color: inherit; } 

.home{
    background: url(../../assets/images/home_bg.jpg);
    background-size: cover;
    position: relative;
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: right;
    align-items: center;
}
.home .info{
    max-width: 600px;
    margin-top: 50px;
    text-align: right;
    background: var(--black);
    opacity: 0.7;
    padding: 4rem;
    margin-right: 2rem;
    background: black;
    opacity: 0.6;
    padding: 10px 60px 5px 5px;
}
.home .info h1{
    font-size: 2.5rem;
    color: white;
    line-height: 1.8;
    text-shadow: var(--text-shadow);
}
.home .info h3{
    font-size: 1.5rem;
    color: white;

}
.home .info p{
    font-size: 1.2rem;
    color: white;
    line-height: 1.8;
    padding: 1rem 0;

}

.about-row-1-col{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #00BFFF;
    padding-bottom: 20px;
    text-shadow: .15rem .15rem 0 rgba(0, 0, 0, .2);
}


.about-row-2{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-row-2-img{
    width: 100%;
}

.about-row-2-col-1{
    display: flex;
    justify-content: center;
    max-width: 50%;
}

.about-row-2-col-2{
    max-width: 50%;
}

.content h3{
    padding: 8px;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: .15rem .15rem 0 rgba(0, 0, 0, .2);
}

.content p{
    font-size: 1rem;
    padding: 8px;
    line-height: 1.8;
}

.btn{
    display: inline-block;
    justify-content: center;
    border: 1px solid #00BFFF;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #00BFFF;

}

.btn:hover{
    color:white;
    background-color:#00BFFF;
}


.doctors{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.doc-box{
   
    display: flex;
    justify-content: center;
}

.doctors-1 h1{
    font-size: 3rem;
    font-weight: bold;
    color: #00BFFF;
    padding-bottom: 20px;
    text-transform: uppercase;
    text-shadow: .15rem .15rem 0 rgba(0, 0, 0, .2);
}

.doctors-1 p{
    font-size: 1.1rem;
    padding: 10px 60px 20px 60px;
    justify-content: center;
    line-height: 1.8;
}

.doc-1{
    border: 1px solid #00BFFF;
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow:.3rem .3rem 0 rgba(49, 190, 237, 0.2);
    width: 15%;
    
}

.doc-1 h3{
    font-size: 1.2rem;
    font-weight: bold;
    padding: 5px;
}

.doc-1 span{
    font-size: 1rem;
    color: #00BFFF;
    
}

/* CSS for doctorsCard */

.doc-container-image{
    width: 60%;
    object-fit: cover;
    height: 150px;
}


.departments .heading{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    color: #00BFFF;
    text-shadow: .15rem .15rem 0 rgba(0, 0, 0, .2);
    padding-bottom: 100px;
    text-transform: uppercase;
}

.dept-box{
    display: flex;
    justify-content: center;
    margin: 10px;
    width: 100%;
    
}

.dept{
    margin: 20px ;
    border: 1px solid #00BFFF;
    border-radius: 10px;
    padding: 20px;
    width: 20%;
    display: flex;
    justify-content: center;
    box-shadow: .3rem .3rem 0 rgba(49, 190, 237, 0.2);

}

.dept-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.dept-container p{
    font-size: 1.2rem;
    padding: 10px;
    
}

.dept-container .fontIcon{
    color: #00BFFF;
    font-size: 4rem;
}

.footer-row{
    padding: 8rem 4rem 8rem 4rem;
    background: #777;
    color: white;
}

.footer-col h3{
    font-size: 2rem;
    color: white;
    padding: 1rem 0;
}

.footer-col p{
    font-size: 1.1rem;
    padding: 1rem; 
}

.footer-col span{
    padding: 10px;
}

.footer-col .icons{
    font-size: 2.5rem;
}

.footer-col .icons a{
    padding: 1rem;
}


/* .about .row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 0 3rem;
}



.about .row .image img{
    width: 100%
}

.about .row .image{
    flex: 1 1 45rem;
}
 */


 @media (max-width: 767px) {
    .about-row-2 {
        flex-direction: column;
      }

    .doc-box, .dept-box {
        flex-direction: column;
        align-items: center;
    }
    .doc-1, .dept {
        width: 40%;
    }
    .about-row-2-col-1, .about-row-2-col-2{
        max-width: 100%;
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content h3, p{
        text-align: center;
    }

    .departments .heading{
        text-align: center;
    }

    .footer-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    }
    .footer-col h3{
        text-align: center;
    }
    
    .footer-col p{
        font-size: 1.1rem;
        padding: 0.5rem; 
    }
    
    .footer-col .icons{
        font-size: 2rem;
    }
  }