.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .app-logo{
  background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .app-logo-img{
      height: 64px;
      padding: 10px 0px 10px 10px;
    }
    .app-logo-title{
      font-size: x-large;
    font-weight: 600;
    }
}

.navbar-style{
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
  flex: 1;
  min-width: 0,
}

.header-style{
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
} */

.content-style{
  background: white;
}


